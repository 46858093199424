import "../../Style/Home.scss";
import Banner from "../../Assets/Images/LandingPage/Banner-1.webp";
import Image1 from "../../Assets/Images/LandingPage/Image-1.png";
import Image2 from "../../Assets/Images/LandingPage/Image-2.png";
import Image3 from "../../Assets/Images/LandingPage/Image-3.png";
import Banner2 from "../../Assets/Images/Projects/B-1.png";
import Arrow from "../../Assets/Button/Arrow 3.png";
import { gsap } from "gsap";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { HashLink } from "react-router-hash-link";
import cld from "../../config/cloudcf";
import { AdvancedImage } from "@cloudinary/react";
import { motion } from "framer-motion";
import SmoothScroll from '../Smoothscroll';
function Home() {
  gsap.registerPlugin(ScrollTrigger)
  useEffect(() => {

    gsap.to(".h1", {
      opacity: 1,
      duration: 2,
      delay: 4
    });
    gsap.to(".image", {
      opacity: 2,
      duration: 2,
      delay: 4.5
    });
    gsap.to(".Content-Section", {
      scrollTrigger: {
        trigger: ".Content-Section",
        start: "20px 80%",
        end: "bottom 100px"
      },
      width: "100%",
      duration: 1,
      ease: "Power2.easeOut",
      delay: 0
    });
    gsap.to(".img", {
      scrollTrigger: {
        trigger: ".Content-Section",
        start: "20px 80%",
        end: "bottom 100px"
      },
      opacity: 2,
      duration: 1,
      delay: 1,
      ease: "Power2.easeOut",
    });
    gsap.from(".p-sec-2", {
      x: -600,
      opacity: 0,
    });

    gsap.to(".p-sec-2", {
      scrollTrigger: {
        trigger: ".Content-Section",
        start: "20px 80%",
        end: "bottom 100px"
      },
      x: 0,
      opacity: 1,
      duration: 1,
      delay: 1,
      ease: "Power2.easeOut",
    });
    gsap.from(".btn-sec-2", {
      x: -600,
      opacity: 0,
    });
    gsap.to(".btn-sec-2", {
      scrollTrigger: {
        trigger: ".Content-Section",
        start: "20px 80%",
        end: "bottom 100px"
      },
      x: 0,
      opacity: 1,
      duration: 1,
      delay: 1,
      ease: "Power2.easeOut",
    });
  });
  const Banner = cld.image("rbn/home/home-banner_adrukg");
  const Home1 = cld.image("rbn/home/home-2");
  const Home2 = cld.image("rbn/projects/K-3");
  const Home3 = cld.image("rbn/projects/bangli-main_1");
  return (
    <div className="Home-Container">
      <div className="Home-Wrapper">
        <div className="Home-Section-1">
          <motion.h1
            initial={{
              opacity: 0
            }}
            whileInView={{
              opacity: 1
            }}
            transition={{
              duration: 2,
              delay: 4
            }}
            viewport={{
              once: true
            }}
          >Leading the Change with  <span>Sustainable Innovation</span> <span>for Global Decarbonization</span></motion.h1>
          <AdvancedImage className="image" cldImg={Banner} alt="RBN Banner Landing" />
        </div>
        <div className="Home-Section-2">
          <div className="Content-Section">
            <div className="Section-2-Left">
              <AdvancedImage className="img" cldImg={Home1} />
            </div>
            <div className="Section-2-Right">
              <p className="p-sec-2">
                <span> About Risjadson</span>
                <span className="margin-10"> progressive, synergistic partnership:</span>
                <span className="margin-10"> Shaping the future of the digital built environment industry through embracing digitalisation and MMoC technologies for the next-generation sustainable development projects.</span>
              </p>
              <Button className="btn-sec-2" href="/about" >More</Button>
            </div>
          </div>
        </div>
        <div className="Home-Section-3">
          <motion.div
            initial={{
              opacity: 0
            }}
            whileInView={{
              opacity: 1
            }}
            transition={{
              duration: 1,
            }}
            viewport={{
              once: true
            }}
            className="Our-Vision">
            <div className="context">
              <div className="Section-3-Left">
                <motion.p
                  initial={{
                    opacity: 0
                  }}
                  whileInView={{
                    opacity: 1
                  }}
                  transition={{
                    duration: 1,
                    delay: 0.2
                  }}
                  viewport={{
                    once: true
                  }}
                >Our Vision</motion.p>
              </div>
              <div className="Section-3-Right">
                <motion.p
                  initial={{
                    opacity: 0
                  }}
                  whileInView={{
                    opacity: 1
                  }}
                  transition={{
                    duration: 1,
                    delay: 0.2
                  }}
                  viewport={{
                    once: true
                  }}
                >To create the best digital built environment for sustainability and the future of our children</motion.p>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{
              opacity: 0
            }}
            whileInView={{
              opacity: 1
            }}
            transition={{
              duration: 1,
            }}
            viewport={{
              once: true
            }}
            className="Our-Mission">
            <div className="context">
              <div className="Section-3-Left">
                <motion.p
                  initial={{
                    opacity: 0
                  }}
                  whileInView={{
                    opacity: 1
                  }}
                  transition={{
                    duration: 1,
                    delay: 0.4
                  }}
                  viewport={{
                    once: true
                  }}

                >Our Mission</motion.p>
              </div>
              <div className="Section-3-Right">
                <motion.p
                  initial={{
                    opacity: 0
                  }}
                  whileInView={{
                    opacity: 1
                  }}
                  transition={{
                    duration: 1,
                    delay: 0.4
                  }}
                  viewport={{
                    once: true
                  }}
                >To implement the next-generation property development and construction projects that embrace the transformative power of a scalable and sustainable digital project ecosystem platform, enabled by the adoption of modern methods construction (MMoC) and IR4.0 technologies in our integrated BIM-based Design-Assemble-Manage (DAM) Project Delivery Model</motion.p>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="Home-Section-4">
          <div className="Our-Projects">
            <motion.h2
              initial={{
                opacity: 0
              }}
              whileInView={{
                opacity: 1
              }}
              transition={{
                duration: 2,
                delay: 0.2
              }}
              viewport={{
                once: true
              }}
            >Our Latest Projects</motion.h2>
          </div>
          <div className="Box-1">
            <div className="Kalimantan">
              <motion.div
                initial={{
                  opacity: 0,
                }}
                whileInView={{
                  opacity: 1,
                }}
                transition={{
                  duration: 1,
                  delay: 0.2
                }}
                viewport={{
                  once: true
                }}
                className="Text">
                <HashLink className="a" to={"/projects#Kalimantan"}>

                  <p>
                    IKN APARTMENT
                    <span>HOUSING</span>
                  </p>
                  <img src={Arrow} />
                </HashLink>
              </motion.div>
              <div className="Rectangle"></div>
              <AdvancedImage cldImg={Home2} />
            </div>
          </div>
          <div className="Box-2">
            <div className="Bali">
              <motion.div
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 1,
                delay: 0.2
              }}
              viewport={{
                once: true
              }}
              className="Text">
                <HashLink to={"/projects#Bali"} className="a">
                  <img src={Arrow} />
                  <p>
                    PROJECT 4PH
                  </p>
                </HashLink>
              </motion.div>
              <div className="Rectangle"></div>
              <img src={Banner2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

import React from 'react'
import '../../Style/Projects.scss'
import Banner from "../../Assets/Images/Projects/projects-banner.webp";
import { Accordion } from "react-bootstrap";
import { useAccordionButton, } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import $ from 'jquery'
import HorizontalScroll from 'react-scroll-horizontal'
import k1 from "../../Assets/Images/Projects/K-1.png";
import k2 from "../../Assets/Images/Projects/K-2.png";
import k3 from "../../Assets/Images/Projects/K-3.png";
import k4 from "../../Assets/Images/Projects/K-4.png";
import b1 from "../../Assets/Images/Projects/B-1.png";
import b2 from "../../Assets/Images/Projects/B-2.png";
import b3 from "../../Assets/Images/Projects/B-3.png";
import b4 from "../../Assets/Images/Projects/B-4.png";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
        if (eventKey === "0") {
            $('#1').toggleClass('white')
            $('#2').removeClass('white')
            $("html, body").animate({
                scrollTop: $("#Kalimantan").offset().top - 140
            }, 100);
        }
        else {
            $('#2').toggleClass('white')
            $('#1').removeClass('white')
            setTimeout(function () {
                $("html, body").animate({
                    scrollTop: $("#Bali").offset().top - 160
                }, 100);
            }, 400)
        }
    }
    );

    return (
        <button
            type="button"
            style={{ color: '#FDBF4A', backgroundColor: "transparent" }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

function Project() {
    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        gsap.from(".Line", {
            width: 0
        })
        gsap.to(".Line", {
            scrollTrigger: {
                trigger: ".Line"
            },
            width: "100%",
            duration: 2
        })
    })
    return (
        <div className='Projects-Container'>
            <div className='Section-1'>
                <div className='Title-Container'>
                    <h1>RBN Projects</h1>
                    <div className='Line-Box'>
                        <span className='Line' />
                    </div>
                </div>
                <img src={Banner} />
            </div>
            <div className='Section-2'>
                <div className="Accordion-Section" >
                    <Accordion>
                        <Card >
                            <Card.Header id='1'>
                                <h2 id='Kalimantan'>KALIMANTAN</h2>
                                <CustomToggle eventKey="0">
                                    <span>+</span>
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Slide autoplay={false}>
                                        <div className="each-slide-effect">
                                            <img src={k1} />
                                        </div>
                                        <div className="each-slide-effect">
                                        <img src={k2} />
                                        </div>
                                        <div className="each-slide-effect">
                                        <img src={k3} />
                                        </div>
                                        <div className="each-slide-effect">
                                        <img src={k4} />
                                        </div>
                                    </Slide>
                                    <HorizontalScroll style={{ height: "50%", width: "100%" }} reverseScroll='true'>
                                        <img style={{ marginRight: "20px", width: "100%" }} src={k1} />
                                        <img style={{ marginRight: "20px", width: "100%" }} src={k2} />
                                        <img style={{ marginRight: "20px", width: "100%" }} src={k3} />
                                        <img style={{ marginRight: "20px", width: "100%" }} src={k4} />
                                    </HorizontalScroll>
                                    <h3>IKN Apartment Housing</h3>
                                    <p className='Desc'>By the end of 2024 the President is to mandate relocation of 16.000 civil servants to IKN as the first residents of the new capital. We aim to create an interconnected community and green living ecosystem for our residents. By June 2024 we aim to complete 155 apartment housing towers.</p>
                                    <div className='Detail-Section'>
                                        <div className='Top-Section'>
                                            <div className='Column' >
                                                <span className='sub-title'>Sector</span>
                                                <p>
                                                    Hospitality &
                                                    <span>Residental</span>
                                                </p>
                                            </div>
                                            <div className='Column' >
                                                <span className='sub-title'>Status</span>
                                                <p>Ongoing</p>
                                            </div>
                                        </div>
                                        <div className='Bottom-Section'>
                                            <div className='Column' >
                                                <span className='sub-title'>Location</span>
                                                <p>
                                                    Kalimantan,
                                                    <span>Indonesia</span>
                                                </p>
                                            </div>
                                            <div className='Column' >
                                                <span className='sub-title'>Scope</span>
                                                <p>
                                                    Architecture,
                                                    <span>Masterplanning</span>
                                                </p></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card >
                            <Card.Header id='2'>
                                <h2 id='Bali'>PHILIPPINES</h2>
                                <CustomToggle eventKey="1">
                                    <span>+</span>
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">

                                <Card.Body>
                                    <Slide autoplay={false}>
                                        <div className="each-slide-effect">
                                            <img src={b1} />
                                        </div>
                                        <div className="each-slide-effect">
                                        <img src={b2} />
                                        </div>
                                    </Slide>
                                    <HorizontalScroll style={{ height: "50%", width: "100%" }} reverseScroll='true'>
                                        <img style={{ marginRight: "20px", width: "100%" }} src={b1} />
                                        <img style={{ marginRight: "20px", width: "100%" }} src={b2} />
                                    </HorizontalScroll>
                                    <h3>Project 4PH Philippines</h3>
                                    <p className='Desc'>Risjadson Bhumi Nusantara (RBN) recently participated in the 4PH (Subsidized Housing Program) in the Philippines. RBN has been entrusted by its local partners to construct 180,000 units by 2029, with Phase I commencing in June 2024, involving the initiation of 7,000-10,000 units.</p>
                                    <div className='Detail-Section'>
                                        <div className='Top-Section'>
                                            <div className='Column' >
                                                <span className='sub-title'>Sector</span>
                                                <p>
                                                Hospitality & 
                                                    <span>Residential</span>
                                                </p>
                                            </div>
                                            <div className='Column' >
                                                <span className='sub-title'>Status</span>
                                                <p>Ongoing</p>
                                            </div>
                                        </div>
                                        <div className='Bottom-Section'>
                                            <div className='Column' >
                                                <span className='sub-title'>Location</span>
                                                <p>
                                                Davao,
                                                    <span>Philippines</span>
                                                </p>
                                            </div>
                                            <div className='Column' >
                                                <span className='sub-title'>Scope</span>
                                                <p>
                                                Superstructure, 
                                                    <span>Manufacturing</span>
                                                </p></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                </div>
            </div>
        </div>
    )
}

export default Project;
import React from 'react'
import "../../Style/Contact.scss"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import image1 from "../../Assets/Images/Contact/image1.png"
import image2 from "../../Assets/Images/Contact/image2.png"
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect } from 'react';
import cld from '../../config/cloudcf';
import { AdvancedImage } from '@cloudinary/react';
import maps from "../../Assets/Images/Contact/maps.png";
import emailjs from "@emailjs/browser"
function Contact() {
    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        gsap.from(".Line", {
            width: 0
        })
        gsap.to(".Line", {
            scrollTrigger: {
                trigger: ".Line"
            },
            width: "100%",
            duration: 2
        })
    })
    const Contact1 = cld.image('rbn/contact/contact_2_up')
    const Contact2 = cld.image('rbn/contact/contact_2')

    const sendEmail = (e) => {
        e.preventDefault();
        alert("Submited")

        emailjs.sendForm('service_44uul7x', 'template_qwieyku', e.target, 'Qaa3M9NKE5orwtySZ')
    }
    return (
        <div className='Contact-Container'>
            <div className='Section-1'>
                <div className='Contact-Title'>
                    <h1>Get In Touch</h1>
                    <div className='Line-Box'>
                        <span className='Line' />
                    </div>
                </div>
                <AdvancedImage cldImg={Contact1} />
            </div>
            <div className='Section-2'>
                <div className='Section-2-Title'>
                    <span>General enquires</span>
                    <a href="mailto: contact@risjadsonnusantara.com">
                        <span>contact@risjadsonnusantara.com</span>
                    </a>
                </div>
                <div className='Form-Section'>
                    <Form className='form' onSubmit={sendEmail}>
                        <Form.Group className="mb-3" >
                            <Form.Control type="name" name='name_from' placeholder="Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="email" name='email_from' placeholder="E-mail" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" name='comment_from' rows={3} placeholder="Comments" />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </div>
                <div className='Maps-Container'>
                    <div className='Maps-Detail'>
                        <span className='Maps-Title'>Where to Find Us</span>
                        <p className='Maps-Desc'>Menara Batavia 30th Floor
                            Jl. K.H. Mas Mansyur, Kav. 126 Jakarta 10250 Indonesia
                            <span>Phone. : (021) 572 2470</span>
                            <span> (Hunting) Fax : (021) 572 2472</span>
                        </p>
                        <button className='Button-Container-Desktop'>
                            <a target="_blank" href='https://www.google.com/maps/place/Menara+Batavia+Building+Management/@-6.2107062,106.8133399,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f6aa0ce995f9:0xed935b94a739c157!8m2!3d-6.2107062!4d106.8155286!16s%2Fm%2F0wfj_1v'>
                                View Maps
                            </a>
                        </button>
                    </div>
                    <div className='Maps'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2764.460180111871!2d106.8153073854185!3d-6.210519312998017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f6aa0ce995f9%3A0xed935b94a739c157!2sMenara%20Batavia%20Building%20Management!5e0!3m2!1sen!2sid!4v1680674304872!5m2!1sen!2sid"  style={{ border: 0, width: "100%", height: "100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <div className='Button-Container-Mobile'>
                            <button >
                                <a target="_blank" href='https://www.google.com/maps/place/Menara+Batavia+Building+Management/@-6.2107062,106.8133399,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f6aa0ce995f9:0xed935b94a739c157!8m2!3d-6.2107062!4d106.8155286!16s%2Fm%2F0wfj_1v'>
                                    View Maps
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
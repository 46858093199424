import React from 'react'
import Logo from '../Assets/Images/Loading/Logo.png'
import '../Style/Loading.scss'
import { useEffect } from 'react'
import { preLoaderAnim } from './Animation'
function Loading() {
    useEffect(() =>{
        preLoaderAnim()
    })
  return (
    <div className='preloader'>
        <span>
          <img className='image' src={Logo} />
        </span>
    </div>
  )
}

export default Loading
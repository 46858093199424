import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Aboutus from './Components/Pages/Aboutus';
import Home from './Components/Pages/Home';
import Footer from './Components/Pages/Footer';
import Nav from './Components/Pages/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import Who from './Components/Pages/Who';
import Project from './Components/Pages/Project';
import Contact from './Components/Pages/Contact';
import { useEffect, useState } from 'react';
import Loading from './Components/Loading';

function App() {
  const checkPath = window.location.pathname === '/' ? <Loading /> : '';
  return (
    <>
      <Router>
        <Nav />
          <div>
            {checkPath}
            <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/about-us' element={<Aboutus />} />
              <Route path='/who' element={<Who />} />
              <Route path='/about' element={<Aboutus />} />
              <Route path='/projects' element={<Project />} />
              <Route path='/contact' element={<Contact />} />

            </Routes>
          </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;

import image1 from "../../Assets/Images/About/Image-1.png"
import image2 from "../../Assets/Images/About/Image-2.png"
import { Accordion } from "react-bootstrap";
import "../../Style/About.scss"
import Card from 'react-bootstrap/Card';
import Data from '../../Assets/Data/About.json';
import Data2 from '../../Assets/Data/ESG.json';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import $ from 'jquery'
import { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import RBM from "../../Assets/Images/About/Image-3.png";
import RBP from "../../Assets/Images/About/Image-4.png";
import RBMwhite from "../../Assets/Images/About/RBM-white.png"
import RBPwhite from "../../Assets/Images/About/RBP-white.png" 
import RBMblack from "../../Assets/Images/About/RBMblack.png"
import RBPblack from "../../Assets/Images/About/RBPblack.png"

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log("changes something")
    );

    return (
        <button
            type="button"
            style={{ color: '#FDBF4A', backgroundColor: "black" }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

function CustomToggle2({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
        if (eventKey === "0") {
            $('#1').toggleClass('white')
            $('#2').removeClass('white')
            $('.logo-white').toggleClass('d-none')
            $('.logo-black').toggleClass('d-none')
            $('.logo-white1').removeClass('d-none')
            $('.logo-black1').addClass('d-none')
        }
        else {
            $('#2').toggleClass('white')
            $('#1').removeClass('white')
            $('.logo-white1').toggleClass('d-none')
            $('.logo-black1').toggleClass('d-none')
            $('.logo-white').removeClass('d-none')
            $('.logo-black').addClass('d-none')
            
        }
    }
    );

    return (
        <button
            type="button"
            style={{ color: '#FDBF4A', backgroundColor: "transparent" }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}



function Aboutus() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.from(".Line", {
            width: 0
        })
        gsap.to(".Line", {
            scrollTrigger: {
                trigger: ".Line"
            },
            width: "100%",
            duration: 2
        })
        gsap.from(".h-8", {
            width: 0
        })
        gsap.to(".Line", {
            scrollTrigger: {
                trigger: ".About-Section-2"
            },
            width: "100%",
            duration: 2
        })
    })
    return (
        <div className="About-Container">
            <section className="About-Section-1" id="risjadsongroup">
                <div className="Title-Section-1" >
                    <h1>Risjadson Land</h1>
                    <div className="Line-Box">
                        <span className="Line" />
                    </div>
                </div>
                <div className="Content-Section">
                    <img src={image1} />
                    <p>
                        PT Risjadson Land (RL) has  been established since 1992 by Mr. Ibrahim Risjad & Sons.
                        In 2022 PT Risjadson Land has been given to Mr. Stefan Mahir "SM" (First Grandchild of Ibrahim Risjad) by the Risjadson Group board as a means to expand their group Manufacturing Portofolio.
                        Risjadson Land is the developer arm for PT. Risjadson Group.
                        <span>
                            PT Risjadson Group  is a long standing privately owned reputable investment and holding company established by Mr. Ibrahim Risjad and Sons.
                            Under Mr. Risjad’s leadership the Risjad family holdings has had the privilege of being associated with First Class Indonesian as well as region “Blue Chip '' assets, some of which are still retained in the form of asset portfolio.
                        </span>
                        <span>
                            Under SM, Risjadson Land saw the potential for the Modern Method of Constructions (MMoC) especially in developing countries. With technology discovered by the company it aims to create cost efficient, sustainable and precise delivery of construction work.
                        </span>
                    </p>
                </div>
            </section>
            <section className="About-Section-2" id="risjadsonland">
                <div className="Title-Section-2" >
                    <div className="Text-Container">
                        <div className="Title">
                            <h2>Risjadson</h2>
                            <div className="Line-Box">
                                <span className="Line h-8" />
                            </div>
                        </div>
                        <div className="Title">
                            <h2>Bhumi</h2>
                            <div className="Line-Box">
                                <span className="Line h-4" />
                            </div>
                        </div>
                        <div className="Title">
                            <h2>Nusantara</h2>
                            <div className="Line-Box">
                                <span className="Line h-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Content-Section">
                    <img src={image2} />
                    <p>
                        Risjadson Bhumi Nusantara (RBN) is a subsidiary of PT Risjadson Land, the Property and Developer arm of Risjadson Group. Helmed by SM, the President Director of the Company, the Company is in an expansion drive based on the global need for modern methods of construction (MMoC) through the manufacturing of modular housing to overcome age long weaknesses in traditional construction methods. SM and his management team has been involved and experienced in numerous manufacturing projects such as lubricants, ceramics, sugar mills and more.
                        Over the last year, the Company has identified the potential of MMoC in the islamic developing countries in the Middle East and other Islamic countries such as Indonesia, Malaysia, Egypt, Kazakhstan, and Brunei among others to complement the existing business in developed nations.
                    </p>
                </div>
            </section>
            <section className="About-Section-3" id="ourleader">
                <Accordion>
                    <Card>
                        <Card.Header id="Sec-3">
                            <p>Our Leaders’ Past Involvement</p>
                            <CustomToggle eventKey="0">+</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <h3>Our leader's notable involvement throughout the years</h3>
                                <div className="Accordion-content" >
                                    {
                                        Data && Data.map((props) => {
                                            return (
                                                <Card style={{ width: '25rem' }} key={props.id} >
                                                    <Card.Img variant="top" src={`images/About/${props.image}`} />
                                                    <Card.Body>
                                                        <Card.Title>{props.title}</Card.Title>
                                                        <Card.Text>
                                                            {props.desc}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            );
                                        })
                                    }
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </section>
            <div className="About-Section-4" id="esg">
                <h2>ESG Values</h2>
                <div className="Text-Section">
                    <p>
                        Embedding Sustainability and ESG Values is our focus and fundamental part of everything we do as a responsible project delivery partner for integrated Design-Assembly-Manage services.
                        <span>
                            With our commitment to co-creating sustainable and technology-driven solutions, we aim to keep up with transformative success through our ESG Goals.
                            We are dedicated to propel new value creations based on high technology by delivering products and services with ESG Benefits.
                        </span>
                        <span>
                            Risjadson Bhumi Nusantara aims to preserve the green environment by fostering eco-friendly buildings and increasing the amount of green space, while using environmentally compatible energy sources.
                            We highly valued and encouraged social interactions and healthy lifestyle by accommodating communal-centric complex design with ease of accessibility.
                        </span>
                        <span>
                            Every step and process we took should be in compliance within the governance rules and guidelines, ensuring transparency and accountability in every process.
                        </span>
                        <span>
                            Moving Forward Together
                        </span>
                        To Achieve Sustainability, Scalability, and Replicability
                    </p>
                </div>
                <div className="Accordion-Section" id="rbm">
                    <Accordion>
                        <div className="card-box">
                            <Card >
                                <Card.Header id="1">
                                        <img src={RBMwhite} className="logo-white" />
                                        <img src={RBMblack} className="logo-black d-none" />
                                    <CustomToggle2 eventKey="0">
                                        <span>+</span>
                                    </CustomToggle2>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <h3>Risjadson Bhumi Modular</h3>
                                        <p>Risjadson Bhumi Modular led by Mr. Budi Susilo is an established global manufacturer in modular prefabrication construction. RBM has construction projects in the UK, US, Malaysia, Australia and now Indonesia. The company aims to create durable and resilient buildings for smart homes fitted with the latest technology, energy-efficient solutions that set the benchmark for Modern Methods of Constructions (MMoC).</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                                <span className="Line"></span>
                                <Card.Header  id="2">
                                        <img src={RBPwhite} className="logo-white1" />
                                        <img src={RBPblack} className="logo-black1 d-none" />
                                    <CustomToggle2 eventKey="1">
                                        <span>+</span>
                                    </CustomToggle2>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <h3>Risjadson Bhumi Propertindo</h3>
                                        <p>Risjadson Bhumi Propertindo, founded in 2021, is a subsidiary specializing in Operator and Management (OM) and Engineering, Procurement & Construction (EPC). During the Kalimantan Project RBP will be partnering with its counterpart RBM to provide efficiency and optimal construction work delivery. The team will be directed by Mr. Abdulbar M. Mansoer.</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </div>
                    </Accordion>
                </div>
            </div>
        </div>
    );
}

export default Aboutus;
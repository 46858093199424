import { Cloudinary } from "@cloudinary/url-gen";

const cld = new Cloudinary({
    cloud: {
      cloudName: 'doy2qixs5',
      apiKey:'181629448781217',
      apiSecret: 'EjzXv7qP5eWSbBmVAyjXGVtEbNg'
    }
  });

export default cld